import YouTube from 'react-youtube';

const videoID = 'pkdIlMftrgQ';

const opts = {
    height: '480',
    width: '854',
};

const VideoDisplay = () => (
    <div className="product-video">
      <YouTube videoId={videoID} opts={opts} />
    </div>
);

export default VideoDisplay
