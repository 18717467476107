import { Button, Link } from '@nextui-org/react';

const joinWaitlist = "https://9dhea0auf2c.typeform.com/to/hdXnMYns";

const Waitlist = () => (
    <div className="App-waitlist">
        <Button
          size="xl"
          shadow
          color="gradient"
          as={Link}
          rel="noopener noreferrer" target="_blank"
          href={ joinWaitlist }
          css={{
              fontSize: "0.8em",
          }}
        >
          Join Our Waitlist
        </Button>
    </div>
);

export default Waitlist
