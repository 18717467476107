import './App.css';
import './sticker.css';
import { Text, Spacer, Button, Navbar, Link, Table, Image } from '@nextui-org/react';
import VideoDisplay from './youtube.js'
import Sticker from './sticker.js'
import Waitlist from './waitlist.js'

function App({ Component }) {
  return (
    <div className="App">
      <header className="App-navbar">
        <Navbar isBordered>
          <Navbar.Brand>
            <Text b
              size={35}
              css={{
                textGradient: "45deg, $blue600 -20%, $pink600 50%",
              }}
            >
              WolongAI
            </Text>
          </Navbar.Brand>
          <Navbar.Content>
            <Navbar.Item>
              Built for Builders
            </Navbar.Item>
          </Navbar.Content>
        </Navbar>
      </header>

      <header className="App-header">
        <div className="App-statement">
          <Text
              h1
              size={55}
              css={{
                textGradient: "45deg, $blue600 -20%, $red600 60%",
                margin: "0px",
              }}
              weight="bold"
          >
            Meet Wolong,<br/>ChatGPT for Your Business
          </Text>
        </div>
        <Waitlist />
        <div className="App-product-video">
          <VideoDisplay />
        </div>
      </header>
      <body>
        <div className="App-mission">
          <Text
              h1
              size={55}
              css={{
                textGradient: "45deg, $blue600 -20%, $red600 60%",
                margin: "0px",
              }}
              weight="bold"
          >
            Multiples hats, on Wolong
          </Text>
        </div>
        <Table
            css={{
                height: "auto",
                minWidth: "100%",
            }}
        >
            <Table.Header>
                <Table.Column></Table.Column>
                <Table.Column></Table.Column>
                <Table.Column></Table.Column>
            </Table.Header>
            <Table.Body>
                <Table.Row key="1">
                    <Table.Cell>
                        <div>
                            <Sticker header="Data Analyst" color="#fff8dc">
                                <span class="fa fa-star checked" /> Automate data collection, cleaning, <br />and analysis for faster insights <br /> <br />
                                <span class="fa fa-star checked" /> Use machine learning algorithms help <br />identify patterns and trends in large datasets <br /> <br />
                                <span class="fa fa-star checked" /> Generate reports and visualizations <br />provide accessible data insights for better <br />decision-making
                            </Sticker>
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>
                            <Sticker header="Business Strategist" color="#FFDCB3">
                                <span class="fa fa-star checked" /> Automate data collection, market <br />research, and trend analysis<br /><br />
                                <span class="fa fa-star checked" /> Use machine learning algorithms identify <br/>patterns and insights to inform strategies <br/><br/>
                                <span class="fa fa-star checked" /> Provide creativity and context to develop<br /> innovative strategies and make informed <br/>decisions
                            </Sticker>
                        </div>
                    </Table.Cell>
                    <Table.Cell>
                        <div>
                            <Sticker header="Executive Assistant" color="#F8DCFF">
                                <span class="fa fa-star checked" /> Automate repetitive tasks such as<br/> scheduling meetings and managing emails <br/> <br/>
                                <span class="fa fa-star checked" /> Use machine learning algorithms to help<br/> with research to support decision-making <br/> <br/>
                                <span class="fa fa-star checked" /> Provide a personalized touch and <br />critical thinking to find solutions to complex<br />problems
                            </Sticker>
                        </div>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
        <Spacer />
        <div className="App-mission">
          <Text
              h1
              size={55}
              css={{
                textGradient: "45deg, $blue600 -20%, $red600 60%",
                margin: "0px",
              }}
              weight="bold"
          >
            Inspired by Your Data
          </Text>
        </div>
        <div className="App-chat-screenshot">
            <img src="/wolong-question.png" alt="Wolong Questions" />
        </div>
        <Spacer />
        <div className="App-mission">
          <Text
              h1
              size={55}
              css={{
                textGradient: "45deg, $blue600 -20%, $red600 60%",
                margin: "0px",
              }}
              weight="bold"
          >
            Make Faster, More Informed Decisions
          </Text>
        </div>
        <Spacer />
        <Waitlist />
        <Spacer />
        <div className="App-mission">
          <Text
              h1
              size={55}
              css={{
                textGradient: "45deg, $blue600 -20%, $red600 60%",
                margin: "0px",
              }}
              weight="bold"
          >
            Become Superhuman at Work
          </Text>
        </div>
        <Spacer />
        <div className="App-product-hunt">
            <a href="https://www.producthunt.com/posts/wolongai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-wolongai" target="_blank">
                <Image
                width={250}
                height={54}
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=383845&theme=light"
                alt="WolongAI - Help&#0032;builders&#0032;make&#0032;faster&#0044;&#0032;more&#0032;informed&#0032;decisions | Product Hunt"
                objectFit="cover"/>
            </a>
        </div>
      </body>
      <footer className="App-footer">
            <div className="footer">
                <p>&copy; {new Date().getFullYear()} WolongAI. All rights reserved.</p>
            </div>
      </footer>
    </div>
  );
}

export default App;
